import Vue from 'vue'
import VueRouter from 'vue-router'
import LayoutView from "@/views/LayoutView.vue";
import LoginView from "@/views/LoginView.vue";
import UserView from "@/views/UserView.vue";
import AdminView from "@/views/AdminView.vue";
import UpdateView from "@/views/UpdateView.vue";
import Mp4_sourceView from "@/views/Mp4_sourceView.vue";
import Mp3_sourceView from "@/views/Mp3_sourceView.vue";
import KeyView from "@/views/KeyView.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/',
    name: 'Layout',
    component: LayoutView,
    children:[
      {
        path: 'admin',
        name: 'admin',
        component:AdminView
      },
      {
        path: 'user',
        name: 'user',
        component: UserView
      },
      {
        path: 'update',
        name: 'update',
        component: UpdateView
      },
      {
        path: 'mp4_source',
        name: 'mp4_source',
        component: Mp4_sourceView
      },{
        path: 'mp3_source',
        name: 'mp3_source',
        component: Mp3_sourceView
      },{
        path: 'key',
        name: 'key',
        component: KeyView
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// 路由守卫
router.beforeEach((to ,from, next) => {
  const user = localStorage.getItem("user");
  if (!user && to.path !== '/login'&& to.path !=='/register') {
    return next("/login");
  }else if(to.path==='/'&&user.role==="boss"){
    return next("/admin")
  }else if(to.path==='/'&&user.role==="admin"){
    return next("/user")
  }
  next();
})

export default router
