<<template>
    <div>
        <el-container>
            <el-aside style="min-height: 100vh; background-color: white; width: 200px">
                <div style="width: 50px;height: 50px;border-radius: 50%;background-color: #bd43fa;margin-left: 10px;margin-top: 20px;float: left">
                    <div STYLE="margin-top: 3px;margin-left: 10px;position: absolute;font-size: xx-large;color: #FFFFFF">AI</div>
                </div>
                <div style="position: absolute;margin-left: 80px;margin-top: 20px;font-size: smaller;color: #494949">editing...</div>
                <div style="position: absolute;margin-left: 70px;margin-top: 35px;font-size: x-large;font-weight: bold">AI剪辑</div>
                <div style="color: #bd43fa;font-weight: bold;font-size: large;position: absolute;top: 120px;left: 20px">Menu</div>
                <div style="margin-top: 160px">
                    <div style="position: relative;cursor: pointer"  @click="admin()" v-if="user.role==='boss'">
                        <div style="width: 35px;height: 35px;border-radius: 50%;background-color: #bd43fa;margin-left: 15px;margin-bottom: 20px;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)"></div>
                        <div class="el-icon-s-custom" style="position: absolute;top: 5px;left: 20px;font-size: x-large;color: #FFFFFF"></div>
                        <el-button style="position: absolute;left: 40px;top: -3px;font-size: large;background-color: transparent;border: none">管理员信息</el-button>
                    </div>
                    <div style="position: relative;cursor: pointer" @click="editing_user()">
                        <div style="width: 35px;height: 35px;border-radius: 50%;background-color: #bd43fa;margin-left: 15px;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)"></div>
                        <div class="el-icon-user-solid" style="position: absolute;top: 5px;left: 20px;font-size: x-large;color: #FFFFFF"></div>
                        <el-button style="position: absolute;left: 40px;top: -3px;font-size: large;background-color: transparent;border: none">用户信息</el-button>
                    </div>
                    <div style="position: relative;cursor: pointer;margin-top: 20px" @click="mp4_source_material()">
                        <div style="width: 35px;height: 35px;border-radius: 50%;background-color: #bd43fa;margin-left: 15px;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)"></div>
                        <div class="el-icon-video-camera" style="position: absolute;top: 5px;left: 20px;font-size: x-large;color: #FFFFFF"></div>
                        <el-button style="position: absolute;left: 40px;top: -3px;font-size: large;background-color: transparent;border: none">视频素材管理</el-button>
                    </div>
                    <div style="position: relative;cursor: pointer;margin-top: 20px" @click="mp3_source_material()">
                        <div style="width: 35px;height: 35px;border-radius: 50%;background-color: #bd43fa;margin-left: 15px;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)"></div>
                        <div class="el-icon-mic" style="position: absolute;top: 5px;left: 20px;font-size: x-large;color: #FFFFFF"></div>
                        <el-button style="position: absolute;left: 40px;top: -3px;font-size: large;background-color: transparent;border: none">音频素材管理</el-button>
                    </div>
                    <div style="position: relative;cursor: pointer;margin-top: 20px" @click="key_material()">
                        <div style="width: 35px;height: 35px;border-radius: 50%;background-color: #bd43fa;margin-left: 15px;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)"></div>
                        <div class="el-icon-key" style="position: absolute;top: 5px;left: 20px;font-size: x-large;color: #FFFFFF"></div>
                        <el-button style="position: absolute;left: 40px;top: -3px;font-size: large;background-color: transparent;border: none">钥匙素材管理</el-button>
                    </div>
                    <div style="position: relative;cursor: pointer;margin-top: 20px" @click="update()">
                        <div style="width: 35px;height: 35px;border-radius: 50%;background-color: #bd43fa;margin-left: 15px;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)"></div>
                        <div class="el-icon-set-up" style="position: absolute;top: 5px;left: 20px;font-size: x-large;color: #FFFFFF"></div>
                        <el-button style="position: absolute;left: 40px;top: -3px;font-size: large;background-color: transparent;border: none">更新日志</el-button>
                    </div>
                </div>
            </el-aside>
            <el-main style="background: linear-gradient(to right, rgba(69,184,248,0.29) 0%, #faeefe 100%); box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)">
                <div style="position: absolute;width: 80%;height: 80px;background-color: #ede8fe;top: 20px;margin-left: 30px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)">
                    <div style="margin: 10px 20px 20px 20px;float: left;position: absolute;right: 80px;top: 10px">
                        <div class="block"><el-avatar :size="50" :src="net_url+user.img"></el-avatar></div>
                    </div>
                    <div style="margin-top: 20px;position: absolute;right: 50px;color: #3b3b3b"><span >{{user.name}}</span></div>
                    <el-popover
                        placement="bottom"
                        trigger="click">
                        <el-button @click="dialogVisible=true">修改密码</el-button>
                        <el-button @click="logout">退出</el-button>
                        <el-button slot="reference" style="border: none;background-color: transparent;font-size: x-small;position: absolute;right: -3px;margin-top: 16px">[设置]</el-button>
                    </el-popover>
<!--                    <div style="margin-top: 25px;position: absolute;right: 10px;color: #3b3b3b;font-size: x-small;cursor: pointer" @click="logout"><span >[设置]</span></div>-->
                    <div style="margin-top: 50px;position: absolute;right: 20px;color: #777676"><span>{{currentTime}}</span></div>
                    <el-dialog
                        title="修改管理员密码"
                        :visible.sync="dialogVisible"
                        width="30%"
                        :before-close="handleClose">
                        <el-form >
                            <el-form-item label="管理员密码" label-width="20%">
                                <el-input v-model="form.password" autocomplete="off" style="width:90%"></el-input>
                            </el-form-item>
                        </el-form>
                        <span slot="footer" class="dialog-footer">
                        <el-button @click="dialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="edit_password">确 定</el-button>
                      </span>
                    </el-dialog>
                </div>
                <router-view/>
                <!--                <div style="background-color: #3d3d3d;width:300px;height: 300px;display: ;">laos</div>-->
            </el-main>
        </el-container>

    </div>
</template>

<script>
import request from "@/utils/request";
import loginView from "@/views/LoginView.vue";

export default {
    name: "Layout.vue",
    data(){
        return{
            user:localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) :{},
            currentTime: new Date().toLocaleTimeString(),
            beforeUnload: '',
            Handler: '',
            ceshi:{},
            form:{},
            about_system:false,
            net_url:loginView.data().net_url,
            dialogVisible:false
        }
    },
    mounted() {
        window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
        window.addEventListener('unload', e => this.unloadHandler(e))
    },
    destroyed() {
        window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
        window.removeEventListener('unload', e => this.unloadHandler(e))
    },
    created() {
        // 开始定时器
        setInterval(this.refreshData, 1000);
    },
    methods:{
        update(){
            if(this.$route.path!=="/update"){
                this.$router.push("/update")
            }
        },
        key_material(){
            if(this.$route.path!=="/key"){
                this.$router.push("/key")
            }
        },
        mp3_source_material(){
            if(this.$route.path!=="/mp3_source"){
                this.$router.push("/mp3_source")
            }
        },
        mp4_source_material(){
            if(this.$route.path!=="/mp4_source"){
                this.$router.push("/mp4_source")
            }
        },
        editing_user(){
            if(this.$route.path!=="/user"){
                this.$router.push("/user")
            }
        },
        admin(){
            if(this.$route.path!=="/admin"){
                this.$router.push("/admin")
            }
        },
        refreshData() {
            this.currentTime = new Date().toLocaleTimeString();
        },
        beforeunloadHandler(){
            this.beforeUnload=new Date().getTime();
        },
        unloadHandler(e){
            this.Handler=new Date().getTime()-this.beforeUnload;
            //判断是窗口关闭还是刷新
            if(this.Handler<=5){
                this.logout();
            }
        },
        logout(){
            localStorage.removeItem("user");
            this.$router.push("/login");
        },
        search(){
            this.$message({
                message: 'success',
                type: 'success'
            });
        },
        edit_password(){
            this.user.password=this.form.password;
            request.post("/admin1",this.user).then(res=>{
                if(res.code==='0'){
                    this.$message.success("修改成功");
                    this.dialogVisible = false
                }
            })
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
        },
    }
}
</script>

<style >
el-menu{
    border-right: none !important;
}
.no-border .el-input__inner {
    border: none !important;
    box-shadow: none !important;
}
</style>