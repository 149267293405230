<template>
    <div >
        <div style="margin-bottom: 15px;margin-top: 100px;margin-left: 40px">
            <div style="float: left;margin-top: 10px;color: #4b4b4b">APP_KEY：</div>
            <input v-model="params.app_key" style="width:120px;float: left;background-color: transparent;border-top: none;border-left:none;border-right: none;
            outline: none;margin-top: 7px;font-size: large;" @change="findGrope">
            <div style="float: left;margin-top: 10px;color: #4b4b4b">AccessKeyId：</div>
            <input v-model="params.AccessKeyId" style="width:120px;float: left;background-color: transparent;border-top: none;border-left:none;border-right: none;
            outline: none;margin-top: 7px;font-size: large;" @change="findGrope">
            <div style="float: left;margin-top: 10px;color: #4b4b4b">AccessKeyIdSecret：</div>
            <input v-model="params.AccessKeyIdSecret" style="width:120px;float: left;background-color: transparent;border-top: none;border-left:none;border-right: none;
            outline: none;margin-top: 7px;font-size: large;" @change="findGrope">
        </div>
        <div style="margin-left: 30px;top: 30px;width: 1285px;position: relative">
            <el-table
                :data="tableData" border style="width: 98%; box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);border-radius: 20px;
                    background-color: transparent" ref="table" v-loading="loading">
                <el-table-column prop="id" label="序列" width="100"></el-table-column>
                <el-table-column prop="app_key" label="app_key" width="200"></el-table-column>
                <el-table-column prop="AccessKeyId" label="AccessKeyId" width="200"></el-table-column>
                <el-table-column prop="AccessKeyIdSecret" label="AccessKeyIdSecret" width="300"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-tooltip content="编辑">
                            <el-button @click="edit(scope.row)" class="el-icon-edit" style="font-size: large;border: none"></el-button>
                        </el-tooltip>
                        <el-tooltip content="删除">
                            <el-button  style="border: none;font-size: large" class="el-icon-delete" @click="del(scope.row.id)"></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div style ="margin-top: 50px;float:left;">
            <span class="demonstration"></span>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="params.pageNum"
                :page-sizes="[5,10, 15, 20]"
                :page-size="params.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </div>
        <el-button style="position: absolute;margin-top: 45px;border-radius: 10px;margin-left: 800px" @click="add">新增</el-button>
        <div>
            <el-dialog title="请填写信息" :visible.sync="dialogFormVisible" width="40%"  :before-close="handleClose">
                <el-form :model="form">
                    <el-form-item label="app_key" label-width="20%">
                        <el-input v-model="form.app_key" autocomplete="off" style="width:90%"></el-input>
                    </el-form-item>
                    <el-form-item label="AccessKeyId" label-width="20%">
                        <el-input v-model="form.AccessKeyId" autocomplete="off" style="width:90%"></el-input>
                    </el-form-item>
                    <el-form-item label="AccessKeyIdSecret" label-width="20%">
                        <el-input v-model="form.AccessKeyIdSecret" autocomplete="off" style="width:90%"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="handleClose">取 消</el-button>
                    <el-button type="primary" @click="submit()">确 定</el-button>
                </div>
            </el-dialog>


        </div>
    </div>
</template>
<script>

import request from "@/utils/request";
import loginView from "@/views/LoginView.vue";


export default {

    data() {

        return {
            params:{
                pageNum:1,
                pageSize:5,
                app_key:'',
                AccessKeyId:'',
                AccessKeyIdSecret:''
            },
            loading: true,
            imageUrl: '',
            load_url:loginView.data().load_url,
            net_url:loginView.data().net_url,
            shan_img:[],
            fit:["contain"],
            notice:{},
            ceshi:[],
            tableData: [],
            total:0,
            dialogFormVisible:false,
            dialogImgVisible:false,
            form:{},
            typeObjs:[],
            detail_img:{},
            del_img:[],
            eidt_mark:'',
            multipleSelection:[],
            multipleSelections:[],
            user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
        }
    },
    //页面加载的时候，做一些事情，在created里面
    created() {
        this.findBySearch();
    },
    //定义一些页面上控件触发的事件调用的方法
    methods:{
        findBySearch(){
            request.get("/key/search",{
                params: this.params
            }).then(res =>{
                if(res.code ==='0'){
                    if(res.data==''&&this.params.pageNum>1){
                        this.loading=true;
                        this.params.pageNum--
                        this.findBySearch()
                    }
                    this.tableData =res.data;
                    this.total= res.total;
                }else{
                    this.$message.error(res.msg)

                }
            })
            setTimeout(()=>{this.loading=false;},1000)
        },
        findGrope(){
            this.loading=true;
            if(this.params.app_key+this.params.AccessKeyId+this.params.AccessKeyIdSecret===''){
                this.findBySearch()
            }else {
                this.params.pageNum=1;
                this.params.pageSize=5;
                request.get("/key/grope",{
                    params:this.params
                }).then(res=>{
                    if(res.code ===0){
                        this.ceshi=res
                        this.tableData=res.data
                        this.total=res.total
                    }else{
                        this.$message.error(res.msg)
                    }
                })
                setTimeout(()=>{this.loading=false;},1000)
            }

        },
        handleClose(){
            this.form={}
            this.dialogFormVisible=false
            this.findBySearch()
        },
        table_refresh(){
            this.loading=true;
            this.findBySearch();
        },
        submit(){
            request.post("/key",this.form).then(res=>{
                if(res.code ==='0'){
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.dialogFormVisible=false;
                    this.findBySearch();
                }else{
                    this.$message.error(res.msg)

                }
            })
        },
        edit(obj){
            this.form=obj;
            this.dialogFormVisible=true;
        },
        successUpload(res){
            this.form.img=res.data;
        },
        add(){
            this.form={};
            this.dialogFormVisible=true;
        },
        handleSizeChange(pageSize){
            this.params.pageSize =pageSize;
            this.loading=true;
            if(this.params.AccessKeyId+this.params.app_key+this.params.AccessKeyIdSecret===''){
                this.findBySearch()
            }else {
                this.findGrope()
            }
        },
        handleCurrentChange(pageNum){
            this.params.pageNum =pageNum;
            this.loading=true;
            if(this.params.AccessKeyId+this.params.app_key+this.params.AccessKeyIdSecret===''){
                this.findBySearch()
            }else {
                this.findGrope()
            }
        },
        del(id){
            request.delete("/key/"+id).then(res=>{
                if(res.code ==='0'){
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.findBySearch();
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        handleSelectionChange(val){
            this.multipleSelection = val;
        },
        getRowKeys(row){
            return row.id;
        }
    }
}
</script>
<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>