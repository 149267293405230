<template>
    <div style="overflow: hidden">
        <div style="margin-bottom: 15px;margin-top: 100px;margin-left: 40px">
            <div style="float: left;margin-top: 10px;color: #4b4b4b">素材名：</div>
            <input v-model="params.source_name" style="width:120px;background-color: transparent;border-top: none;border-left:none;border-right: none;
            outline: none;margin-top: 7px;font-size: large" @change="findGrope">
        </div>
        <div style="margin-left: 30px;width: 1285px;margin-bottom: 50px">
            <el-table
                    :data="tableData" border style="width: 98%; box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);border-radius: 20px;
                    background-color: transparent" ref="table" v-loading="loading" >
                <el-table-column label="视频" width="200" >
                    <template  v-slot="scope">
                        <video width="150" height="150" ref="videoElement" controls >
                            <source :src="net_url+scope.row.source_address" type="video/mp4">
                        </video>
                    </template>
                </el-table-column>
                <el-table-column prop="source_name" label="素材名" width="200"></el-table-column>
                <el-table-column prop="source_size" label="素材大小" width="200"></el-table-column>
                <el-table-column prop="source_used_number" label="素材使用量" width="200"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-tooltip content="编辑">
                            <el-button @click="edit(scope.row)" class="el-icon-edit" style="font-size: large;border: none"></el-button>
                        </el-tooltip>
                        <el-tooltip content="删除">
                                <el-button  style="border: none;font-size: large" class="el-icon-delete" @click="del(scope.row.id)"></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-button style="position: absolute;border-radius: 10px;margin-top: 15px;right: 25px" @click="add">新增</el-button>
        </div>
                <div>
            <el-dialog title="请填写信息" :visible.sync="dialogFormVisible" width="30%">
                <el-form :model="form">
                    <el-form-item label="素材名" label-width="20%">
                        <el-input v-model="form.source_name" autocomplete="off" style="width:90%"></el-input>
                    </el-form-item>
                    <el-form-item label="视频" label-width="20%">
                        <el-upload
                            class="upload-demo"
                            drag
                            name="video"
                            action="https://api.tomovideo.com/filess/uploadvideo"
                            :on-success="handleAvatarSuccess"
                            :file-list="fileList"
                            multiple>
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="素材使用量" label-width="20%">
                        <el-input v-model="form.source_used_number" autocomplete="off" style="width:90%" @keyup.enter="submit"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submit()">确 定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>

import request from "@/utils/request";
import axios from "axios";
import loginView from "@/views/LoginView.vue";


export default {

    data() {

        return {
            params:{
                source_name:''
            },
            loading: true,
            imageUrl: '',
            shan_img:[],
            fit:["contain"],
            notice:{},
            ceshi:[],
            tableData: [],
            total:0,
            dialogFormVisible:false,
            dialogImgVisible:false,
            load_url:loginView.data().load_url,
            net_url:loginView.data().net_url,
            form:{},
            typeObjs:[],
            detail_img:{},
            del_img:[],
            multipleSelection:[],
            multipleSelections:[],
            user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
            selectedFile: null,
            fileList: [],
        }
    },
    //页面加载的时候，做一些事情，在created里面
    created() {
        this.findBySearch();
        // this.findTypes();
    },
    //定义一些页面上控件触发的事件调用的方法
    methods:{
        findBySearch(){
            request.get("/mp4_source/search").then(res =>{
                if(res.code ==='0'){
                    if(res.data==''&&this.params.pageNum>1){
                        this.loading=true;
                        this.params.pageNum--
                        this.findBySearch()
                    }
                    this.tableData =res.data;
                    this.total= res.total;
                }else{
                    this.$message.error(res.msg)

                }
            })
            setTimeout(()=>{this.loading=false;},1000)
        },
        findGrope(){
            this.loading=true;
            if(this.params.source_name===''){
                this.findBySearch()
            }else {
                request.get("/mp4_source/grope",{
                    params:this.params
                }).then(res=>{
                    if(res.code ===0){
                        this.ceshi=res
                        this.tableData=res.data
                        this.total=res.total
                    }else{
                        this.$message.error(res.msg)
                    }
                })
                setTimeout(()=>{this.loading=false;},1000)
            }

        },
        table_refresh(){
            this.loading=true;
            this.findBySearch();
        },
        submit(){
            request.post("/mp4_source",this.form).then(res=>{
                if(res.code ==='0'){
                    this.$message({
                        message: '操作成功',
                        type: 'success'
                    });
                    this.dialogFormVisible=false;
                    this.$router.go(0)
                }else{
                    this.$message.error(res.msg)

                }
            })
            this.fileList=[]
            this.imageUrl='';
            this.form={};
        },
        edit(obj){
            this.form=obj;
            this.dialogFormVisible=true;
        },
        successUpload(res){
            this.form.img=res.data;
        },
        add(){
            this.form={};
            this.dialogFormVisible=true;
        },
        handleSizeChange(pageSize){
            this.params.pageSize =pageSize;
            this.findBySearch();

        },
        handleCurrentChange(pageNum){
            this.params.pageNum =pageNum;
            this.findBySearch();
        },
        del(id){
            request.delete("/mp4_source/"+id).then(res=>{
                if(res.code ==='0'){
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.findBySearch();
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        handleAvatarSuccess(data,file) {
            this.form.source_address=data.split('<')[0]
            this.imageUrl=this.net_url+this.form.source_address
            this.form.source_size=(file.size/1024).toFixed()
        },
        getRowKeys(row){
            return row.id;
        },
        //上传视频


    }
}
</script>
<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>