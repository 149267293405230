<template>
    <div  style="background-color: #FFFFFF;height: auto;box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    border-radius: 20px;position: absolute;top: 150px;width: 75%;margin-left: 30px">
        <div style="margin-left: 30px;position: relative;margin-top: 80px">

<!--            <el-table
                    :data="tableData" border style="width: 98%;" ref="table" v-loading="loading" >
                <el-table-column prop="record_id" label="编号" width="200"></el-table-column>
                <el-table-column prop="need" label="需求" width="200"></el-table-column>
                <el-table-column prop="need" label="时间" width="200"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-tooltip content="编辑">
                            <el-button @click="edit(scope.row)" class="el-icon-edit" style="font-size: large;border: none"></el-button>
                        </el-tooltip>
                        <el-tooltip content="删除">
                                <el-button  style="border: none;font-size: large" class="el-icon-delete" @click="del(scope.row.id)"></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>-->
            <el-table
                :data="tableData"
                 v-loading="loading" >
                <el-table-column
                    label="升级内容"
                    prop="update_content">
                </el-table-column>
                <el-table-column
                    label="升级版本"
                    prop="update_version">
                </el-table-column>
                <el-table-column
                    label="升级时间"
                    prop="update_time">
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-tooltip content="删除">
                            <el-button  style="border: none;font-size: large" class="el-icon-delete" @click="del(scope.row.id)"></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-button style="position: absolute;margin-top: 45px;border-radius: 10px;right: 20px" @click="add">新增</el-button>
        </div>
        <div style ="margin-top: 50px;float:left;margin-left: 20px;margin-bottom: 20px">
            <span class="demonstration"></span>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="params.pageNum"
                :page-sizes="[5,10, 15, 20]"
                :page-size="params.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </div>
                <div>
            <el-dialog title="请填写信息" :visible.sync="dialogFormVisible" width="30%">
                <el-form :model="form">
                    <el-form-item label="升级内容" label-width="20%">
                        <el-input v-model="form.update_content" autocomplete="off" style="width:90%" type="textarea"></el-input>
                    </el-form-item>
                    <el-form-item label="升级版本" label-width="20%">
                        <el-input v-model="form.update_version" autocomplete="off" style="width:90%"></el-input>
                    </el-form-item>
                    <el-form-item label="升级时间" label-width="20%">
                        <el-input v-model="form.update_time" autocomplete="off" style="width:90%"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submit()">确 定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>

import request from "@/utils/request";


export default {

    data() {

        return {
            params:{
                pageNum:1,
                pageSize:5
            },
            loading: true,
            imageUrl: '',
            shan_img:[],
            fit:["contain"],
            notice:{},
            ceshi:[],
            total:0,
            dialogFormVisible:false,
            dialogImgVisible:false,
            form:{},
            typeObjs:[],
            detail_img:{},
            del_img:[],
            multipleSelection:[],
            multipleSelections:[],
            user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
            tableData: [],
            tableData_children:[],
            user_id:'',
        }
    },
    //页面加载的时候，做一些事情，在created里面
    created() {
        this.findBySearch();
        // this.findTypes();
    },
    //定义一些页面上控件触发的事件调用的方法
    methods:{
        findBySearch(){
            request.get("/update1/search",{
                params:this.params
            }).then(res =>{
                if(res.code ==='0'){
                    this.ceshi=res
                    this.tableData =res.data;
                    this.total=res.total;
                }else{
                    this.$message.error(res.msg)
                }
            })
            setTimeout(()=>{this.loading=false;},1000)
        },
        table_refresh(){
            this.loading=true;
            this.findBySearch();
        },
        submit(){
            request.post("/update1",this.form).then(res=>{
                if(res.code ==='0'){
                    this.$message({
                        message: '操作成功',
                        type: 'success'
                    });
                    this.dialogFormVisible=false;
                    this.findBySearch();
                }else{
                    this.$message.error(res.msg)

                }
            })
            this.form={};
        },
        edit(obj){
            this.form=obj;
            this.dialogFormVisible=true;
        },
        successUpload(res){
            this.form.img=res.data;
        },
        add(){
            this.imageUrl=''
            this.form={};
            this.dialogFormVisible=true;
        },
        handleSizeChange(pageSize){
            this.params.pageSize =pageSize;
            this.findBySearch();

        },
        handleCurrentChange(pageNum){
            this.params.pageNum =pageNum;
            this.findBySearch();
        },
        del(id){
            request.delete("/update1/"+id).then(res=>{
                if(res.code ==='0'){
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.findBySearch();
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        handleSelectionChange(val){
            this.multipleSelection = val;
        },
        getRowKeys(row){
            return row.id;
        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
            this.form.img=res.data;
        },
        beforeAvatarUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isLt2M;
        },
    }
}
</script>
<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
.demo-table-expand {
    font-size: 0;
}
.demo-table-expand label {
    width: 90px;
    color: #99a9bf;
}
.demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
}
</style>