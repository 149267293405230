<template>
    <div >
        <div style="margin-bottom: 15px;margin-top: 100px;margin-left: 40px">
            <div style="float: left;margin-top: 10px;color: #4b4b4b">昵称：</div>
            <input v-model="params.name" style="width:120px;float: left;background-color: transparent;border-top: none;border-left:none;border-right: none;
            outline: none;margin-top: 7px;font-size: large" @change="findGrope">
            <div style="float: left;margin-top: 10px;color: #4b4b4b;margin-left: 10px">账号：</div>
            <input v-model="params.account_number" style="width:120px;float: left;background-color: transparent;border-top: none;border-left:none;border-right: none;
            outline: none;margin-top: 7px;font-size: large" @change="findGrope">
            <div style="float: left;margin-top: 10px;color: #4b4b4b;margin-left: 10px">电话：</div>
            <input v-model="params.phone" style="width:120px;float: left;background-color: transparent;border-top: none;border-left:none;border-right: none;
            outline: none;margin-top: 7px;font-size: large" @change="findGrope">
            <div style="float: left;margin-top: 10px;color: #4b4b4b;margin-left: 10px">IP地址：</div>
            <input v-model="params.ip_address" style="width:120px;float: left;background-color: transparent;border-top: none;border-left:none;border-right: none;
            outline: none;margin-top: 7px;font-size: large" @change="findGrope">
        </div>
        <div style="margin-left: 30px;top: 30px;width: 1285px;position: relative">
            <el-table
                :data="tableData" border style="width: 98%; box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);border-radius: 20px;
                    background-color: transparent" ref="table" v-loading="loading" >
                <el-table-column label="头像" width="75px;">
                    <template  v-slot="scope">
                        <el-image
                            style="width: 50px; height: 50px;border-radius: 50%"
                            :src="net_url+scope.row.img"
                            :preview-src-list="[net_url+scope.row.img]"
                            :fit="fit">
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="昵称" width="200"></el-table-column>
                <el-table-column prop="account_number" label="账号" width="200"></el-table-column>
                <el-table-column prop="phone" label="电话" width="200"></el-table-column>
                <el-table-column prop="ip_address" label="地址" width="200"></el-table-column>
                <el-table-column label="二维码" width="75px;">
                    <template  v-slot="scope">
                        <el-image
                            style="width: 50px; height: 50px;border-radius: 50%"
                            :src="net_url+scope.row.QR_img"
                            :preview-src-list="[net_url+scope.row.QR_img]"
                            :fit="fit">
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="account_coin" label="金币" width="200"></el-table-column>
                <el-table-column prop="account_offsetting_fund" label="抵用金" width="200"></el-table-column>
                <el-table-column label="操作" fixed="right" width="150">
                    <template slot-scope="scope">
                        <el-tooltip content="编辑">
                            <el-button @click="edit(scope.row)" class="el-icon-edit" style="font-size: large;border: none"></el-button>
                        </el-tooltip>
                        <el-tooltip content="删除">
                            <el-button  style="border: none;font-size: large" class="el-icon-delete" @click="del(scope.row.id)"></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div style ="margin-top: 50px;float:left;">
            <span class="demonstration"></span>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="params.pageNum"
                :page-sizes="[5,10, 15, 20]"
                :page-size="params.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </div>
        <el-button style="position: absolute;margin-top: 45px;border-radius: 10px;margin-left: 730px" @click="add">新增</el-button>
        <div>
            <el-dialog title="请填写信息" :visible.sync="dialogFormVisible" width="30%" :before-close="handleClose">
                <el-form :model="form">
                    <el-form-item label="昵称" label-width="20%">
                        <el-input v-model="form.name" autocomplete="off" style="width:90%"></el-input>
                    </el-form-item>
                    <el-form-item label="头像" label-width="20%">
                        <el-upload
                            class="avatar-uploader"
                            name="img"
                            :action=load_url
                            :on-success="handleAvatarSuccess"
                            :show-file-list="false"
                            :before-upload="beforeAvatarUpload">
                            <img v-if="imageUrl" :src="imageUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="密码" label-width="20%">
                        <el-input v-model="form.password" autocomplete="off" type="password" style="width:90%"></el-input>
                    </el-form-item>
                    <el-form-item label="电话" label-width="20%">
                        <el-input v-model="form.phone" autocomplete="off" style="width:90%"></el-input>
                    </el-form-item>
                    <el-form-item label="IP地址" label-width="20%">
                        <el-input v-model="form.ip_address" autocomplete="off" style="width:90%"></el-input>
                    </el-form-item>
                    <el-form-item label="二维码" label-width="20%">
                        <el-upload
                            class="avatar-uploader"
                            name="img"
                            :action=load_url
                            :on-success="handleQRSuccess"
                            :show-file-list="false"
                            :before-upload="beforeQRUpload">
                            <el-image v-if="QR_imageUrl" :src="QR_imageUrl" class="avatar" :fit="fit"></el-image>
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="handleClose">取 消</el-button>
                    <el-button type="primary" @click="submit()">确 定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>

import request from "@/utils/request";
import loginView from "@/views/LoginView.vue";


export default {

    data() {

        return {
            params:{
                pageNum:1,
                pageSize:5,
                ip_address:'',
                phone:'',
                account_number:'',
                name:''
            },
            loading: true,
            imageUrl: '',
            QR_imageUrl:'',
            shan_img:[],
            fit:["contain"],
            notice:{},
            ceshi:[],
            tableData: [],
            total:0,
            load_url:loginView.data().load_url,
            net_url:loginView.data().net_url,
            dialogFormVisible:false,
            dialogImgVisible:false,
            form:{},
            typeObjs:[],
            detail_img:{},
            del_img:[],
            multipleSelection:[],
            multipleSelections:[],
            user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
        }
    },
    //页面加载的时候，做一些事情，在created里面
    created() {
        this.findBySearch();
        // this.findTypes();
    },
    //定义一些页面上控件触发的事件调用的方法
    methods:{
        findBySearch(){
            request.get("/user1/search",{
                params: this.params
            }).then(res =>{
                if(res.code ==='0'){
                    if(res.data==''&&this.params.pageNum>1){
                        this.loading=true;
                        this.params.pageNum--
                        this.findBySearch()
                    }
                    this.tableData =res.data;
                    this.total= res.total;
                }else{
                    this.$message.error(res.msg)
                }
            })
            setTimeout(()=>{this.loading=false;},1000)
        },
        findGrope(){
            this.loading=true;
            if(this.params.name+this.params.phone+this.params.ip_address+this.params.account_number===''){
                this.params.pageNum=1;
                this.params.pageSize=5;
                this.findBySearch();
            }else {
                request.get("/user1/grope",{
                    params:this.params
                }).then(res=>{
                    if(res.code ===0){
                        this.tableData=res.data
                        this.total=res.total;
                        this.ceshi=res
                    }else{
                        this.$message.error(res.msg)
                    }
                })
                setTimeout(()=>{this.loading=false;},1000)
            }
        },
        handleClose(){
            this.form={}
            this.dialogFormVisible=false
            this.findBySearch()
        },
        table_refresh(){
            this.loading=true;
            this.findBySearch();
        },
        submit(){
            request.post("/user1",this.form).then(res=>{
                if(res.code ==='0'){
                    this.$message({
                        message: '操作成功',
                        type: 'success'
                    });
                    this.dialogFormVisible=false;
                    this.findBySearch();
                }else{
                    this.$message.error(res.msg)

                }
            })
            this.imageUrl='';
            this.form={};
        },
        edit(obj){
            this.imageUrl=this.net_url+obj.img
            this.QR_imageUrl=this.net_url+obj.QR_img
            this.form=obj;
            this.dialogFormVisible=true;
        },
        successUpload(res){
            this.form.img=res.data;
            this.ceshi=this.form.img;
        },
        add(){
            this.imageUrl=''
            this.form={};
            this.ceshi=this.form;
            this.dialogFormVisible=true;
        },
        handleSizeChange(pageSize){
            this.params.pageSize =pageSize;
            if(this.params.name+this.params.phone+this.params.ip_address+this.params.account_number===''){
                this.findBySearch()
            }else {
                this.findGrope()
            }
        },
        handleCurrentChange(pageNum){
            this.params.pageNum =pageNum;
            if(this.params.name+this.params.phone+this.params.ip_address+this.params.account_number===''){
                this.findBySearch()
            }else {
                this.findGrope()
            }
        },
        del(id){
            request.delete("/user1/"+id).then(res=>{
                if(res.code ==='0'){
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.findBySearch();
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        handleSelectionChange(val){
            this.multipleSelection = val;
        },
        getRowKeys(row){
            return row.id;
        },
        handleAvatarSuccess(data) {
            this.form.img=data.split('<')[0]
            this.imageUrl=this.net_url+this.form.img
        },
        handleQRSuccess(data) {
            this.form.QR_img=data.split('<')[0]
            this.QR_imageUrl=this.net_url+this.form.QR_img
        },
        beforeAvatarUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isLt2M;
        },
        beforeQRUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isLt2M;
        }
    }
}
</script>
<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>