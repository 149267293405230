<template >
    <div >
        <div style="background: linear-gradient(to bottom, rgba(69,184,248,0.29) 0%, #faeefe 100%);height: 950px;display: flex;justify-content: center;align-items: center;">
<!--            <div style="position: absolute;background-color: #f7f0fa;height: 400px;width: 600px;top: 25%;left: 35%;border-radius: 20px;box-shadow: 0 15px 25px rgba(0, 0, 0, 0.4);">
                <div style="position: relative;top: 10%;left: 150px;font-size: xx-large">AI视频剪辑管理系统</div>
                <div style="position: relative;top: 10%;left: 150px;font-size: large;color: #727171">Video Editing Management System</div>
                <div style="position: relative;top: 18%;left: 130px;width: 350px;height: 50px">
                    <div class="el-icon-user-solid" style="color: #bac2cb;font-size: x-large;margin-left: 10px;margin-top: 10px"></div>
                    <input type="text" v-model="admin.account_number" autocomplete="off" style="position: absolute;
                    margin-left: 15px;margin-top: 15px;border: none;border-bottom: 1px solid black;outline: none;width: 80%;background-color: transparent">
                </div>
                <div style="position: relative;top: 18%;left: 130px;width: 350px;height: 50px">
                    <div class="el-icon-lock" style="color: #bac2cb;font-size: x-large;margin-left: 10px;margin-top: 10px"></div>
                    <input type="password" v-model="admin.password" autocomplete="off" style="position: absolute;
                    margin-left: 15px;margin-top: 15px;border: none;border-bottom: 1px solid black;outline: none;width: 80%;background-color: transparent">
                </div>
                <div style="position: relative;top: 20%;left: 130px;width: 350px;height: 50px">
                    <img :src="captchaUrl" id="referflush" @click="change_img" width="150px" style="margin-left: 20px;margin-top: 8px">
                    <input v-model="admin.verCode" style="position: relative;border:none;outline: none;margin-top: 15px;width: 40%;background-color: transparent;;border-bottom: 1px solid black;
                    margin-left: 15px;font-size: large" placeholder="请输入验证码"  @keyup.enter="login">
                </div>
                <el-button style="position: relative;top: 25%;left: 150px;border: none;background-color: #02aaf4;color: #FFFFFF;
                border-radius: 10px;width: 200px" @click="login()" v-loading.fullscreen.lock="loading">登录</el-button>
                <el-link style="position: relative;top: 25%;left: 170px" target="_blank" @click="dialogFormVisible=true">注册</el-link>
            </div>-->
            <div style="background-color:transparent;height: 400px;width: 800px;border-radius: 20px;
            box-shadow: 0 15px 25px rgba(0, 0, 0, 0.4)">
                <div style="width: 50%;height: 100%;float: left" class="change_login">
                    <div style="">
                        <div style="font-size: xx-large;display: flex;align-items: center;justify-content: center;margin-top: 30%">AI视频剪辑</div>
                        <div style="font-size: xx-large;display: flex;align-items: center;justify-content: center">管理系统</div>
                        <div style="display: flex;align-items: center;justify-content: center">Video Editing Management System</div>
                    </div>
                </div>
                <div style="width: 50%;height: 100%;background-color: #f7f0fa;border-radius: 0 20px 20px 0;float: left" >
                    <div style="display: flex;justify-content: center;font-size: xx-large;font-weight: bold;color: #737373;margin-top: 10%">登录</div>
                    <div style="display: flex;justify-content: center;margin-top: 10%">
                        <div class="el-icon-user-solid" style="color: #bac2cb;font-size: x-large"></div>
                        <input type="text" v-model="admin.account_number" autocomplete="off" style="margin-left: 10px;font-size: large;
                    border: none;border-bottom: 1px solid black;outline: none;background-color: transparent">
                    </div>
                    <div style="display: flex;justify-content: center;margin-top: 5%">
                        <div class="el-icon-lock" style="color: #bac2cb;font-size: x-large"></div>
                        <input type="password" v-model="admin.password" autocomplete="off" style="margin-left: 10px;font-size: large;
                    border: none;border-bottom: 1px solid black;outline: none;background-color: transparent">
                    </div>
                    <div style="display: flex;justify-content: center;margin-top: 5%">
                        <img :src="captchaUrl" id="referflush" @click="change_img" width="150px" style="">
                        <input v-model="admin.verCode" style="border:none;outline: none;background-color: transparent;width: 25%;border-bottom: 1px solid black;
                    font-size: large" @keyup.enter="login">
                    </div>
                    <div style="display: flex;justify-content: center;margin-top: 7%">
                        <el-button style="border: none;background-color: #02aaf4;color: #FFFFFF;height: 30px;display: flex;align-items: center;justify-content: center;
                border-radius: 10px;width: 100px" @click="login()" v-loading.fullscreen.lock="loading">登录</el-button>
                    </div>
                    <div style="display: flex;justify-content: center;margin-top: 5%">
                        <div style="width: 60%;height: 1px;background-color: #969696"></div>
                    </div>
                    <div style="display: flex;justify-content: center;margin-top: 3%">
                        <el-link  target="_blank" @click="dialogFormVisible=true">注册</el-link>
                    </div>
                </div>
<!--                <div style="width: 50%;height: 100%;background-color: #f7f0fa;border-radius: 20px 0 0 20px;float: left" v-if="change_login===false">
                    <div style="display: flex;justify-content: center;font-size: xx-large;font-weight: bold;color: #737373;margin-top: 10%">登录</div>
                    <div style="display: flex;justify-content: center;margin-top: 10%">
                        <div class="el-icon-user-solid" style="color: #bac2cb;font-size: x-large"></div>
                        <input type="text" v-model="admin.account_number" autocomplete="off" style="margin-left: 10px;font-size: large;
                    border: none;border-bottom: 1px solid black;outline: none;background-color: transparent">
                    </div>
                    <div style="display: flex;justify-content: center;margin-top: 5%">
                        <div class="el-icon-lock" style="color: #bac2cb;font-size: x-large"></div>
                        <input type="password" v-model="admin.password" autocomplete="off" style="margin-left: 10px;font-size: large;
                    border: none;border-bottom: 1px solid black;outline: none;background-color: transparent">
                    </div>
                    <div style="display: flex;justify-content: center;margin-top: 5%">
                        <img :src="captchaUrl" id="referflush" @click="change_img" width="150px" style="">
                        <input v-model="admin.verCode" style="border:none;outline: none;background-color: transparent;width: 25%;border-bottom: 1px solid black;
                    font-size: large" @keyup.enter="login">
                    </div>
                    <div style="display: flex;justify-content: center;margin-top: 7%">
                        <el-button style="border: none;background-color: #02aaf4;color: #FFFFFF;height: 30px;display: flex;align-items: center;justify-content: center;
                border-radius: 10px;width: 100px" @click="login()" v-loading.fullscreen.lock="loading">注册</el-button>
                    </div>
                    <div style="display: flex;justify-content: center;margin-top: 5%">
                        <div style="width: 60%;height: 1px;background-color: #969696"></div>
                    </div>
                    <div style="display: flex;justify-content: center;margin-top: 3%">
                        <el-link  target="_blank" @click="change_login=true">注册</el-link>
                    </div>
                </div>
                <div style="width: 50%;height: 100%;float: left" v-if="change_login===false">
                    <div style="">
                        <div style="font-size: xx-large;display: flex;align-items: center;justify-content: center;margin-top: 30%">AI视频剪辑</div>
                        <div style="font-size: xx-large;display: flex;align-items: center;justify-content: center">管理系统</div>
                        <div style="display: flex;align-items: center;justify-content: center">Video Editing Management System</div>
                    </div>
                </div>-->
            </div>
        </div>
        <div>
            <el-dialog title="请填写信息" :visible.sync="dialogFormVisible" width="30%">
                <el-form :model="form">
                    <el-form-item label="昵称" label-width="20%">
                        <el-input v-model="form.name" autocomplete="off" style="width:90%"></el-input>
                    </el-form-item>
                    <el-form-item label="头像" label-width="20%">
                        <el-upload
                            class="avatar-uploader"
                            name="img"
                            :action="this.load_url"
                            :on-success="handleAvatarSuccess"
                            :show-file-list="false"
                            :before-upload="beforeAvatarUpload">
                            <img v-if="imageUrl" :src="imageUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="账号" label-width="20%">
                        <el-input v-model="form.account_number" autocomplete="off" style="width:90%"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" label-width="20%">
                        <el-input v-model="form.password" autocomplete="off" style="width:90%" type="password"></el-input>
                    </el-form-item>
                    <el-form-item label="电话" label-width="20%">
                        <el-input v-model="form.phone" autocomplete="off" style="width:90%"></el-input>
                    </el-form-item>
                    <el-form-item label="IP地址" label-width="20%">
                        <el-input v-model="form.ip_address" autocomplete="off" style="width:90%"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submit()">确 定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>


<script>
import request from "@/utils/request";
export default {
    name:"Login",
    data() {
        return {
            user:localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) :{},
            admin:{},
            loading:false,
            onloading:false,
            key:"",
            num:1111,
            form:{
                img:'',
                name:'',
                password:'',
                phone:'',
                ip_address:'',
                account_number:'',
            },
            captchaUrl:"",
            net_url:'https://api.tomovideo.com/',
            load_url:"https://api.tomovideo.com/filess/uploadImg",
            imageUrl: '',
            dialogFormVisible:false
        }

    },
    //页面加载的时候，做一些事情，在created里面
    created() {
    },
    mounted() {
        this.change_img();
    },
    //定义一些页面上控件触发的事件调用的方法
    methods:{
        login(){
            this.loading=true;
            setTimeout(()=>{
                this.onloading=false;
                request.post("/admin1/login",this.admin).then(res =>{
                    if(res.code ==='0'){
                        this.$message({
                            message: '登录成功',
                            type: 'success'
                        });
                        localStorage.setItem("user",JSON.stringify(res.data));
                        // window.location.href="http://localhost:63342/project1/index.html?type="+this.user.name;
                        if(res.data.role==="boss"){
                            this.$router.push({
                                path:"/admin"
                            })
                        }else if(res.data.role==="admin") {
                            this.$router.push({
                                path:"/user"
                            })
                        }else{
                            this.$message.error("未授权登录，请联系系统管理者")
                            setTimeout(()=>{
                                this.$router.go(0)
                            },2000)
                        }
                    }else{
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        });
                        setTimeout(()=>{
                            this.$router.go(0)
                        },2000)

                    }
                })

            },1500);

        },
        change_img(){
            this.captchaUrl=this.net_url+'captcha'
        },
        submit(){
            if(this.form.account_number*this.form.ip_address*this.form.phone*this.form.password*this.form.name*this.form.img==''){
                this.$message.error("请填写完整信息")
            }else{
                this.form.role='user'
                request.post("/admin1/register",this.form).then(res=>{
                    if(res.code ===0){
                        this.$message({
                            message: '注册成功',
                            type: 'success'
                        });
                        this.dialogFormVisible=false;
                    }else{
                        this.$message.error(res.msg)
                    }
                })
                this.form={};
            }

        },
        handleAvatarSuccess(data) {
            this.form.img=data.split('<')[0]
            this.imageUrl=this.net_url+this.form.img
        },
        beforeAvatarUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isLt2M;
        }
    }
}
</script>
<